<template>
  <div>
    <vca-field :label="$t('taking.form.crew.header')">
      <vca-dropdown
        ref="crew"
        v-model="crewsList"
        :options="crewList"
        :title="$t('taking.form.crew.title')"
        :rules="$v.value.crew_id"
        label=""
        :errorMsg="$t('taking.form.crew.errorMsg')"
        :placeholder="$t('taking.form.crew.placeholder')"
      />
    </vca-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TakingFormCrewSelect",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.crew.validate();
    },
  },
  computed: {
    crewsList: {
      set(value) {
        if (value.length > 0) {
          this.value.crew_id = value[0].value;
          //this.updateCrewAspList()
        } else {
          this.value.crew_id = null;
        }
      },
      get() {
        return !this.value.crew ? [] : [{ value: this.value.crew_id }];
      },
    },
    ...mapGetters({
      crewList: "crews/dropdown",
      validation: "takings/validations",
    }),
  },
};
</script>
