<template>
  <div>
    <TakingAddForm v-model="create" ref="form" />
    <button class="vca-button" @click="add()">
      {{ $t("button.save") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TakingAddForm from "@/components/finance/takings/add/TakingAddForm";
export default {
  name: "TakingAdd",
  components: { TakingAddForm },
  methods: {
    add() {
      if (this.isInvalid) {
        this.$refs.form.validate();
      } else {
        this.$store.dispatch({ type: "takings/create" });
      }
    },
  },
  validations() {
    return { ...this.validation, ...this.source_validations };
  },
  computed: {
    isInvalid() {
      return this.$v.create.$invalid || this.invalidSources != null;
    },
    invalidSources() {
      return this.create.sources.find(
        (el) => el.money == undefined || el.money.amount == 0
      );
    },
    create: {
      set(value) {
        this.$store.commit("takings/create", value);
      },
      get() {
        return this.$store.state.takings.create;
      },
    },
    has_cash() {
      return this.create.sources
        ? this.create.sources.reduce((b, a) => !a.has_external || b, false)
        : false;
    },
    ...mapGetters({
      validation: "takings/validations",
    }),
    getJSON() {
      return JSON.stringify(this.create, null, 4); // Indented 4 spaces
    },
  },
};
</script>
