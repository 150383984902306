<template>
  <vca-card>
    <h2>{{ $t("taking.form.header") }}</h2>
    <TakingAdd />
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import TakingAdd from "@/components/finance/takings/TakingAdd";
export default {
  name: "TakingsAdd",
  components: { TakingAdd },
  created() {
    this.$store.dispatch({ type: "crews/public" });
    if (!this.hasSystemPermission()) {
      //this.create.crew = { id: this.user.crew.crew_id };
      this.create.crew_id = this.user.crew.crew_id;
    }
  },
  computed: {
    create: {
      set(value) {
        this.$store.commit("takings/create", value);
      },
      get() {
        return this.$store.state.takings.create;
      },
    },
    ...mapGetters({
      user: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
